.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  margin-left: auto;
  margin-right: auto;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background-color: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}
.about__me-image {
  /* margin-left: 1.5rem; */
  border-radius: 4rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;

  transition: transform 0.3s ease;
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
  transform: scale(1.1);
}
.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: whitesmoke;
}

/* MEDIA QUEIRIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .about__container {
    /* display: grid; */
    grid-template-columns: 1fr;
    gap: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1.5rem 1.5rem 1.5rem 1.5rem;
    text-align: center;
    /* padding-left: 6rem; */
  }
  .about__cards {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;

    /* padding-left: 6rem; */
  }
}

/* MEDIA QUEIRIES (SMALL DEVICES)  */

@media screen and (max-width: 600px) {
  .about__container {
    display: grid;
    justify-content: center;
    align-items: center; /* Center vertically */
    margin: 0 auto 5rem;
  }

  .about__me {
    width: 65%;
    gap: 1.5rem;
  }

  .about__cards {
    display: grid;
    grid-template-columns: 1fr;
    padding: 2px;
    justify-content: center;
    margin: auto;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem;
    padding: 2%;
  }

  .about__me-image {
    margin: 0 auto; /* Center the image horizontally */
    width: 100%;
  }
}
