header {
  height: 100vh;
  padding-top: 7rem;
  position: relative;
  justify-items: center;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

/* CTS Style */

.cta {
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* Socials style*/

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}
.header__socials a {
  transition: var(--transition);
}
.header__socials a:hover {
  transform: scale(1.2);
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ME style */
.me {
  background: transparent;
  width: 22rem;
  height: 50vh;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 1rem;
  overflow: hidden;
  padding: 3rem;
  border: 2px solid transparent;
  object-fit: contain;
}

/* Scroll Down style */

.scroll__down {
  position: absolute;
  right: -4rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .container {
    width: 86%;
  }

  me {
    width: 50%;
    height: 100vh;
    margin: 2rem auto;
  }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
