.container.contact__container {
  width: 68%;
  display: flex;
  /* grid-template-columns: 30% 50%; */
  justify-content: center;
  gap: 12%;
  padding-bottom: 8rem;
  margin-left: auto;
  margin-right: auto;
}

.contact__options {
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  justify-content: center; /* Center the grid horizontally */
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: transform 0.3s ease;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  transform: scale(1.1);
}

.contact__option-icons {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

/* MEDIA QUERIES (MEDIUM DEVICES) */

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* MEDIA QUERIES (SMALL DEVICES) */

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
