.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.portfolio__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: transform 0.3s ease;
  position: relative; /* Ensure the relative position for absolute positioning */
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  transform: scale(1.1);
}

.portfolio__item-image {
  border-radius: 1rem;
  overflow: hidden;
  position: relative; /* Ensure relative position for absolute positioning */
}

.portfolio__item-description {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.portfolio__item:hover .portfolio__item-description {
  display: block;
  transform: scale(1.1);
}

.portfolio__item h3 {
  margin: 3rem 1rem 1rem 2rem;
}

.portfolio__item-cta {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: auto;
}

/* Media Queries (Medium Devices) */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

/* Media Queries (Small Devices) */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}
